<template>
  <div class="content-body">
    <section class="">
      <div class="row">
        <!--            customer information-->
        <div class="col-lg-4 mt-1 mt-lg-0">
          <div class="card h-100">
            <div class="card-body">
              <div>
                <div class="d-flex justify-content-between" data-toggle="collapse" href="#billingInformation"
                     role="button">
                  <p class="text-bold-500"> BILLING ADDRESS</p>
                  <i class='bx bx-chevron-down'></i>
                </div>
                <div class="collapse collapse show" id="billingInformation">
                  <div class="form-group">
                    <div class="">
                      <span class="text-bold-500"> Address : </span>
                      <span v-if="customer.address !==undefined">{{ billingAddress.address.fullAddress }}</span>
                    </div>
                  </div>

                </div>
              </div>
              <div>

                <div class="d-flex justify-content-between" data-toggle="collapse" href="#shippingAddress"
                     role="button">
                  <p class="text-bold-500"> SHIPPING ADDRESS</p>
                  <i class='bx bx-chevron-down'></i>
                </div>

                <div class="collapse" id="shippingAddress">
                  <div class="custom-control custom-checkbox d-flex align-items-center mb-1">
                    <input v-model="sameBillingShippingAddressStatus" class="custom-control-input" type="checkbox"
                           id="billingAddress">
                    <label class="custom-control-label" for="billingAddress">Same as billing address</label>
                  </div>

                  <div class="form-group">
                    <div class=""><label class="text-bold-400">Address</label>
                      <span v-if="sameBillingShippingAddressStatus !== true" style="cursor: pointer;" title="Edit"
                            class="ml-1 single-edit-btn text-primary" data-toggle="modal"
                            data-target="#userAddressCreate"><i class='bx bx-edit-alt'></i></span>
                      <input readonly type="text" class="payment-input-field"
                             v-model="shippingAddress.address.fullAddress"></div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Delivery Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="w-100 ">
                        <DatePicker v-model="delivery_date" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                    :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue ,togglePopover  }">
                            <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                              <i class='bx bx-calendar mr-1'></i>
                              <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                            </div>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-2">
                  <button class="btn btn-primary text-uppercase" @click="backToOrderCreate">back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--            discount information-->
        <div class="col-lg-4 mt-1 mt-lg-0">
          <div class="card h-100">
            <div class="card-body">
              <p class="text-bold-500">DISCOUNT & OTHERS</p>

              <div class="">
                <div class="">
                  <div class="">
                    <div class="custom-control custom-checkbox d-flex align-items-center">
                      <input class="custom-control-input" type="checkbox" id="applyCoupon" v-model="applyCoupon">
                      <label class="custom-control-label" for="applyCoupon">Apply
                        Coupon</label><small data-toggle="modal" data-target="#availableCouponsModal"
                                             class="text-primary" v-if="coupons.length > 0" style="cursor: pointer">(Click
                      to see all
                      available coupon)</small>
                    </div>

                  </div>
                  <div v-if="applyCoupon">
                    <div class="row py-1">
                      <div class="col-9 align-self-center"><input v-model="coupon_code" placeholder="Enter a Coupon"
                                                                  type="text" class="w-100 payment-input-field"></div>
                      <div class="col-3 align-self-center pl-0 d-flex align-items-center">
                        <button class="btn btn-primary btn-sm" @click="applyDiscountHandler">Apply</button>
                      </div>
                    </div>
                    <div class="" v-if="preOrderResponse.coupon_discount.validation_status === false">
                      <p class="text-danger">{{ preOrderResponse.coupon_discount.message }}</p>
                    </div>
                    <div class="" v-if="preOrderResponse.coupon_discount.validation_status === true">
                      <p class="text-success">{{ preOrderResponse.coupon_discount.message }}</p>
                    </div>
                  </div>
                </div>
                <!--              start credited section-->
                <div class="">
                  <div class="">
                    <div class="custom-control custom-checkbox d-flex align-items-center">
                      <input class="custom-control-input" type="checkbox" id="applyCredit" v-model="applyCredit">
                      <label class="custom-control-label" for="applyCredit">Apply Credit (<span
                          class="display-inline-block text-danger">{{
                          `Remaining credit: ${parseFloat(preOrderResponse.remaining_credited_payment / 100).toFixed(2)}`
                        }}</span>)</label>
                    </div>
                  </div>
                  <div v-if="applyCredit">
                    <div class="row py-1">
                      <div class="col-9 align-self-center"><input v-model="credit_code" placeholder="Enter a Credit"
                                                                  type="text" class="w-100 payment-input-field"></div>
                      <div class="col-3 align-self-center pl-0 d-flex align-items-center">
                        <button class="btn btn-primary btn-sm" @click="applyDiscountHandler">Apply</button>

                      </div>
                    </div>
                    <div v-if="preOrderResponse.credited_payment_discount.validation_status === false">
                      <p class="text-danger">{{ preOrderResponse.credited_payment_discount.message }}</p>
                    </div>
                    <div v-if="preOrderResponse.credited_payment_discount.validation_status === true">
                      <p class="text-success">{{ preOrderResponse.credited_payment_discount.message }}</p>
                    </div>
                  </div>

                </div>
                <!--              end credited section-->
                <!--                    start discount section-->
                <div class="row">
                  <div class="col-12">
                    <p class="pt-2 text-bold-500">Discount</p>

                  </div>
                  <div class="col-12 ">
                    <!-- any -->
                    <div class="custom-control-inline custom-control custom-radio">
                      <input v-model="discount_type" type="radio" class="custom-control-input" id="anyDiscounts"
                             name="discount_type" value="any">
                      <label class="custom-control-label font-weight-bold" for="anyDiscounts">Any</label>
                    </div>
                    <!-- five percent -->
                    <div class="custom-control-inline custom-control custom-radio">
                      <input @change="applyDiscountHandler" v-model="discount_type" type="radio"
                             class="custom-control-input" id="fivePerDiscount" name="discount_type" value="5">
                      <label class="custom-control-label font-weight-bold" for="fivePerDiscount">5%
                        discount</label>
                    </div>
                    <!-- ten percent -->
                    <div @change="applyDiscountHandler" class="custom-control-inline custom-control custom-radio">
                      <input v-model="discount_type" type="radio" class="custom-control-input" id="tenPerDiscount"
                             name="discount_type" value="10">
                      <label class="custom-control-label font-weight-bold" for="tenPerDiscount">10%
                        discount</label>
                    </div>
                    <!-- twenty percent -->
                    <div class="custom-control-inline custom-control custom-radio">
                      <input @change="applyDiscountHandler" v-model="discount_type" type="radio"
                             class="custom-control-input" id="twentyPerDiscount" name="discount_type" value="20">
                      <label class="custom-control-label font-weight-bold" for="twentyPerDiscount">20%
                        discount</label>
                    </div>

                    <div class="row py-1" v-if="discount_type === 'any'">
                      <div class="col-9 align-self-center"><input v-model="discount_amount"
                                                                  placeholder="Enter discount amount" type="text"
                                                                  class="w-100 payment-input-field"></div>
                      <div class="col-3 align-self-center pl-0 d-flex align-items-center">
                        <button class="btn btn-primary btn-sm" @click="applyDiscountHandler">Apply</button>
                      </div>
                    </div>

                  </div>

                </div>

                <!--                    end discount section-->

              </div>
              <!--                  start order note section-->
              <p class="mt-1 text-bold-500">ORDER NOTE (OPTIONAL)</p>
              <textarea v-model="order_note" placeholder="Order note (Max 128 characters)" rows="2" maxlength="128"
                        class="form-control mb-2"></textarea>
              <!--                  end order note section-->

              <div class="row ">
                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label> Enable Delivery Fee</label>
                      <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="orderDeliveryChargeStatus" type="checkbox" class="custom-control-input"
                                   checked id="orderDeliveryChargeStatus">
                            <label class="custom-control-label mr-1" for="orderDeliveryChargeStatus"></label>
                          </div>
                          <span class="font-medium-1">{{ orderDeliveryChargeStatus ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--            payment information-->
        <div class="col-lg-4 mt-1 mt-lg-0">
          <div class="card h-100">
            <div class="card-body">
              <p class="text-bold-500">ORDER SUMMARY</p>

              <div class="row">
                <div class="col-7 text-left"><span class="text-bold-400">Subtotal:</span></div>
                <div class="col-5 text-right"><span class="">$<span>{{
                    parseFloat(preOrderResponse.subtotal_amount / 100).toFixed(2)
                  }}</span></span></div>
              </div>
              <div class="row">
                <div class="col-7 text-left"><span class="text-bold-400">Cart Discount:</span> <small
                    v-if="(preOrderResponse.total_discount_amount / 100).toFixed(2) > 0 "
                    class="text-primary cursor-pointer" data-toggle="modal" data-target="#discountDetailsModal">(view
                  details)</small>
                </div>
                <div class="col-5 text-right"><span
                    class="">${{ parseFloat(preOrderResponse.total_discount_amount / 100).toFixed(2) }}</span></div>
              </div>
              <div class="row">
                <div class="col-7 text-left">
                                <span class="text-bold-400 ">Delivery Fee

                                    {{
                                    this.preOrderResponse.order_delivery_charge.applied_status === true ?
                                        `(${this.preOrderResponse.order_delivery_charge.distance_value_in_km})` : ''
                                  }}

                                    :</span>
                </div>
                <div class="col-5 text-right">
                  <span>${{ parseFloat(preOrderResponse.order_delivery_charge.amount / 100).toFixed(2) }}</span>
                </div>
              </div>

              <div class="row" v-if="preOrderResponse.gst_charge.applied_status">
                <div class="col-7 text-left"><span class="text-bold-400">GST:</span></div>
                <div class="col-5 text-right"><span
                    class=""> ${{ parseFloat(preOrderResponse.gst_charge.amount / 100).toFixed(2) }} </span></div>
              </div>
              <hr>
              <div class="row" v-if="preOrderResponse.gst_charge.applied_status">
                <div class="col-7 text-left"><span class="text-bold-600">Total :</span></div>
                <div class="col-5 text-right text-bold-600"><span
                    class=""> ${{ parseFloat(preOrderResponse.grand_total / 100).toFixed(2) }} </span></div>
              </div>
              <hr>
              <div class="py-2">
                <button class="btn btn-primary btn-block text-uppercase" :disabled="delivery_date === ''"
                        @click="updateOrder">Update Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DiscountDetailsModal :preOrderResponse="{...preOrderResponse}"/>
      <SurchargeInfoModal :surcharges="surcharge"/>
      <UserAddressCreateOnlyModal :model-primary-address-id="customer.address_id" :user-id="customer.user_id"
                                  :business-status="customer.type==='Business'" modal-name="userAddressCreate"
                                  @emitUserAddress="userAddedAddressData"/>
      <AvailableCouponsModal modal-name="availableCouponsModal" @emitApplyCoupon="applyCouponEmitHandler"/>
      <announcement-modal modal-name="showOrderNo" v-on:confirmActionModel="onConfirmActionModal" model-name="order">
        <template v-slot:announcement-text>Order Create Successfully</template>
        <template v-slot:announcement-reference>{{ order.order_no }}</template>
      </announcement-modal>
      <div class="" data-toggle="modal" data-target="#showOrderNo"></div>

    </section>
  </div>
</template>

<script>
// <!-- import AppLayout from '@/layouts/backEnd/AppLayout.vue'; -->
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import DiscountDetailsModal from "@/DiscountDetailsModal";
import SurchargeInfoModal from "@/views/SurchargeInfoModal";
import {
  mapActions,
  mapGetters
} from 'vuex'
import {
  DatePicker
} from "v-calendar";
import UserAddressCreateOnlyModal from "@/components/backEnd/modal/UserAddressCreateOnlyModal";
import AnnouncementModal from "@/components/backEnd/modal/AnnouncementModal";
import AvailableCouponsModal from "@/views/backEnd/appointments/appointmentCreate/includes/AvailableCouponsModal";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {

    DiscountDetailsModal,
    DatePicker,
    SurchargeInfoModal,
    UserAddressCreateOnlyModal,
    AnnouncementModal,
    AvailableCouponsModal,
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "OrderCheckoutEdit",
  props: ['cartList', 'selectedCustomerInfo'],
  data() {
    return {
      isCashReceived: false,
      withPayment: false,
      notifyToCustomer: false,
      sameBillingShippingAddressStatus: true,
      shippingDetails: {
        name: '',
        email: '',
        phone: '',
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
          business: {
            id: null,
            name: '',
          }
        },

      },
      shippingAddress: {
        name: '',
        email: '',
        phone: '',
        address: {
          id: '',
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
          business: {
            id: null,
            name: '',
          },
          fullAddress: '',
        },

      },
      billingAddress: {
        name: '',
        email: '',
        phone: '',
        address: {
          userId: '',
          id: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
          business: {
            id: null,
            name: '',
          },
          fullAddress: ''
        },

      },
      term_condition: false,
      eft_reference: '',
      order_note: '',
      delivery_date: '',
      applyCoupon: false,
      applyCredit: false,
      paymentType: '',
      cartsData: [],
      discount_type: '',
      coupon_code: '',
      credit_code: '',
      discount_amount: '',
      payment_type: '',
      paid_amount: '',
      payment_method_type: '',
      discountObj: {},
      userId: '',
      errorBag: {
        eftReference: [],
        paymentAmount: [],
      },

      preOrderResponse: {
        message: '',
        status: '',
        remaining_credited_payment: '',
        order_delivery_charge: {
          applied_status: '',
          message: '',
          distance_value: '',
          distance_value_in_km: '',
          amount: '',
        },
        subtotal_amount: 0,
        applied_discount: {
          applied_status: '',
          amount: ''
        },
        coupon_discount: {
          applied_status: '',
          validation_status: '',
          message: '',
          apply_on: '',
          amount: '',
        },
        credited_payment_discount: {
          applied_status: '',
          validation_status: '',
          amount: '',
          message: ''
        },
        total_discount_amount: '',
        gst_charge: {
          applied_status: '',
          amount: ''
        },
        cart_data: [],
        grand_total: '',
        shipping_address_status: false,
        shipping_address: {},
        checkout_data: {},
      },

      customer: {
        address: {},
        address_id: '',
        user_id: '',
        type: ''
      },

      paidAmount: {},
      cardTokenBackendErrors: {},
      // cardInformation
      cardName: '',
      cardNumber: '',
      cardExpiryDate: '',
      cardExpireMonth: '',
      cardExpireYear: '',
      cardCvc: '',

      cardTokenResponse: {
        id: '',
        used: '',
        card: {}
      },
      authorizationTokenResponse: {
        id: '',
        decline_reason: '',
        payment_status: '',
        payment_gateway: '',
      },
      paymentId: '',
      eftReference: '',
      getSettingsParams: {
        type: ['payment'],
        key: ['payment_card_surcharge'],
      },
      paidInfo: {},
      billingAddressId: '',
      shippingAddressId: '',
      orderSuccessDebounce: '',
      order_date: '',
      appointment: {},
      cardPaymentAmountObj: {},
      orderDeliveryChargeStatus: true,
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'appAuthLogin/authUser',
      order: 'appOrders/order',
      payment: 'appPayments/payment',
      coupons: 'appCoupons/coupons',
      previousRoute: 'previousRoute',
      cardSurcharge: 'appSettings/settingPaymentCardSurcharge',

      editOrderTemporalInformation: 'appOrders/editOrderTemporalInformation',
    }),

    surcharge() {
      return this.cardSurcharge.value
    },
    isShippingInfoDataFiled() {
      return !!(this.shippingDetails.name !== '' && this.shippingDetails.email !== '' && this.shippingDetails.phone !== '')
    },

  },

  watch: {
    sameBillingShippingAddressStatus(status) {
      if (status === false) {

        this.shippingAddress.address.id = ""
        this.shippingAddress.address.street = "";
        this.shippingAddress.address.state = "";
        this.shippingAddress.address.suburb = "";
        this.shippingAddress.address.postCode = "";
        this.shippingAddress.address.country = "";
        this.shippingAddress.address.business.id = null;
        this.shippingAddress.address.business.name = "";

        this.shippingAddress.address.fullAddress = "";

      }
      if (status === true) {

        this.shippingAddress.address.id = this.editOrderTemporalInformation.data.selectedCustomer.address.id;
        this.shippingAddress.address.street = this.editOrderTemporalInformation.data.selectedCustomer.address.street;
        this.shippingAddress.address.state = this.editOrderTemporalInformation.data.selectedCustomer.address.state;
        this.shippingAddress.address.suburb = this.editOrderTemporalInformation.data.selectedCustomer.address.suburb;
        this.shippingAddress.address.postCode = this.editOrderTemporalInformation.data.selectedCustomer.address.post_code;
        this.shippingAddress.address.country = this.editOrderTemporalInformation.data.selectedCustomer.address.country;
        this.shippingAddress.address.business.id = this.editOrderTemporalInformation.data.selectedCustomer.address.business?.id ?? null;
        this.shippingAddress.address.business.name = this.editOrderTemporalInformation.data.selectedCustomer.address.business?.name ?? '';

        this.shippingAddress.address.fullAddress = this.customer.type === 'Business' ? `${this.shippingAddress.address.business.name} (${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country})` : `${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country}`
        this.preStoreOrder();
      }
    },
    // customer(customer) {
    //     this.shippingDetails = {
    //         name: customer.full_name,
    //         email: customer.email,
    //         phone: customer.phone_number,
    //         address: {
    //             userId: customer.user_id,
    //             street: customer.address.street,
    //             suburb: customer.address.suburb,
    //             state: customer.address.state,
    //             postCode: customer.address.post_code,
    //             country: customer.address.country,
    //             business: {
    //                 id: customer.address ?.business ?.id,
    //                 name: customer.address ?.business ?.name,
    //             }
    //         }
    //     }
    //     // set billing, shipping address id
    //     this.billingAddressId = customer.address_id;
    //     this.shippingAddressId = customer.address_id;
    // },

    // sameBillingShippingAddressStatus(value) {
    //     this.shippingDetails.name = (value === true) ? this.customer.full_name : '';
    //     this.shippingDetails.email = (value === true) ? this.customer.email : '';
    //     this.shippingDetails.phone = (value === true) ? this.customer.phone_number : '';
    // },

    payment_type(paymentType) {
      this.getPaidAmount(paymentType)
    },
    paid_amount() {
      this.getPaidAmount()
    },
    orderDeliveryChargeStatus() {
      this.preStoreOrder();
    },

    cardExpiryDate(currentExpiryDate) {
      this.cardExpireMonth = currentExpiryDate.slice(0, 2);
      this.cardExpireYear = currentExpiryDate.slice(-2);
      if (currentExpiryDate.length === 5) {
        this.$refs.cvvInput.focus();
      }
    },
    selectedCustomerInfo(selectedCustomerInfo) {
      console.log(selectedCustomerInfo, "selectedCustomerInfo");
    },
    cartList(cartList) {
      console.log(cartList, "cartList");

    }
    // props:['cartList','selectedCustomerInfo'],
  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getAvailableValidCoupons: 'appCoupons/getAvailableValidCoupons',

      preStoreOrderData: 'appOrders/preStoreOrderData',
      cardTokenCreate: 'appPaymentGateways/cardTokenCreate',
      authorizationCreateByToken: 'appPaymentGateways/authorizationCreateByToken',
      paymentCreateByToken: 'appPaymentGateways/paymentCreateByToken',
      paymentCreate: 'appPaymentGateways/paymentCreate',

      postPayment: 'appPayments/postPayment',
      postOrder: 'appOrders/postOrder',
      postOrderNote: 'appOrderNotes/postOrderNote',
      postCardPayment: 'appCardPayments/postCardPayment',
      postCashPayment: 'appCashPayments/postCashPayment',
      postEftPayment: 'appEftPayments/postEftPayment',
      postOrderCreationNotifyEmail: 'appOrders/postOrderCreationNotifyEmail',
      postPaymentCreationNotifyEmail: 'appPayments/postPaymentCreationNotifyEmail',

      postOrderCreator: 'appOrderCreators/postOrderCreator',
      postDiscountStoreList: 'appDiscounts/postDiscountStoreList',

      updateAppointment: 'appAppointments/putAppointment',
      putOrder: 'appOrders/putOrder',
    }),

    getTotalPrice() {
      return ((this.editOrderTemporalInformation?.data?.cartList ?? []).reduce((acc, cur) => acc + Number((cur.product_price.sub_total) ?? 0), 0)); // received subTotal amount in cent
    },

    async updateOrder() {
      if (!this.delivery_date) {
        this.showToastMessage({
          type: 'error',
          message: 'please provide delivery date'
        });
        return;
      }
      if ((!this.sameBillingShippingAddressStatus) && (!(this.shippingAddress.address.id || this.shippingAddress.address.id === 0))) {
        this.showToastMessage({
          type: 'error',
          message: 'Please provide shipping address'
        });
        return;
      }
      this.preOrderResponse.shipping_address = this.shippingAddress;
      this.preOrderResponse.shipping_address_status = this.sameBillingShippingAddressStatus;

      // re assign checkout data
      this.preOrderResponse.checkout_data = {
        coupon_code: this.applyCoupon ? this.coupon_code : '',
        coupon_apply_on: this.applyCoupon ? this.apply_on : '',
        credited_payment_discount_amount: this.applyCredit ? this.credit_code * 100 : '',
        applied_discount_amount: this.discount_type !== 'any' ? this.discount_type : ((this.discount_amount) * 100),
        applied_discount_type: this.discount_type !== 'any' ? 0 : 1,
        apply_delivery_charge: this.orderDeliveryChargeStatus ? 1 : 0
      }

      let dataObj = {
        id: this.order.id,
        data: {
          delivery_date: this.delivery_date,
          details: JSON.stringify(this.preOrderResponse),
          shipping_address_id: this.shippingAddress.address.id,
          billing_address_id: this.billingAddress.address.id
        }
      }

      this.loader(true);
      const response = await this.putOrder(dataObj);
      this.loader(false);
      if (response.status === 200) {
        // again set order temporal data set empty & checkout status false
        this.$store.commit("appOrders/SET_EDIT_ORDER_TEMPORAL_INFORMATION", {
          data: {},
          orderCheckoutIsActive: false
        });
        await this.$router.push({
          name: 'appOrderList'
        });
        this.showToastMessage({
          type: 'success',
          message: 'Order updated successfully'
        });
        return;
      }
      if (response.message) {
        this.showToastMessage(response);
      }

      await this.backToOrderCreate();

    },

    applyCouponEmitHandler(response) {
      document.querySelector('[data-target="#availableCouponsModal"]').click();
      this.applyCoupon = true;
      this.coupon_code = response.couponCode;
      this.apply_on = response.applyOn === 'Shipping Fee' ? 1 : 0;

      this.preStoreOrder();
    },

    async getAvailableValidCouponsHandler() {

      this.loader(true);
      const data = {
        activation_amount: Number(this.preOrderResponse.subtotal_amount),
        user_id: this.customer.user_id,
        //apply_on: 0
      }
      const response = await this.getAvailableValidCoupons(data);
      if (response.message) {
        this.showToastMessage(response);
      }
      this.loader(false);

    },

    async onConfirmActionModal(modalAction) {
      if (modalAction.status === true) {
        clearTimeout(this.orderSuccessDebounce);
        document.querySelector('[data-target="#showOrderNo"]').click();
        // if order from appointment
        if ((this.appointment?.id)) {
          await this.$router.push({
            name: 'appAppointmentList',
          });
          return
        }
        await this.$router.push({
          name: 'appOrderList'
        });

      }
    },

    userAddedAddressData(addressData) {
      this.shippingAddress.address.id = addressData.addressData.id;
      this.shippingAddress.address.business.id = addressData.addressData.business && addressData.addressData.business.id ? addressData.addressData.business.id : null;
      this.shippingAddress.address.business.name = addressData.addressData.business && addressData.addressData.business.name ? addressData.addressData.business.name : '';
      this.shippingAddress.address.street = addressData.addressData.street ?? '';
      this.shippingAddress.address.suburb = addressData.addressData.suburb ?? '';
      this.shippingAddress.address.state = addressData.addressData.state ?? '';
      this.shippingAddress.address.postCode = addressData.addressData.postCode ?? '';
      this.shippingAddress.address.country = addressData.addressData.country ?? '';
      this.shippingAddress.address.fullAddress = this.customer.type === 'Business' ? `${this.shippingAddress.address.business.name} (${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country})` : `${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country}`

      // set billing address id
      this.shippingAddressId = addressData.addressData.id;
      this.preStoreOrder();
    },
    // calculate surcharge
    getSurcharge(surchargeObj, cardType, amount) {
      if (surchargeObj.status === 1) {
        let cardRate = surchargeObj.details.find((cardItem) => {
              return cardItem.name.toLowerCase() === cardType.toLowerCase()
            })?.rate ?? 0,
            surchargeAmount = amount * cardRate,
            totalAmount = amount + surchargeAmount;
        return {
          surcharge_amount: surchargeAmount,
          total_amount: totalAmount
        }
      } else {
        return {
          surcharge_amount: 0,
          total_amount: amount
        }
      }
    },
    // calculate amount by payment type like: half,full,quarter & other
    async getPaidAmount(paymentType = 'other') {

      let paidAmount, restAmount, totalAmount;

      totalAmount = this.preOrderResponse.grand_total;
      if (paymentType === 'other') {
        paidAmount = Number(this.paid_amount) * 100 ?? 0
        if (paidAmount > totalAmount) {
          paidAmount = totalAmount;
          this.paid_amount = parseFloat(totalAmount / 100).toFixed(2)
          //this.errorBag.paymentAmount = [];
          this.errorBag.paymentAmount.push('Exceed total payable amount')
        }
      } else {
        paidAmount = parseInt(totalAmount * (Number(paymentType) / 100))
      }
      restAmount = totalAmount - paidAmount;
      this.paidInfo = {
        paymentType: paymentType,
        paidAmount: paidAmount,
        totalAmount: totalAmount,
        restAmount: restAmount
      }

    },

    // check pre-store order data
    async preStoreOrder() {

      let street = this.shippingAddress.address.street;
      let suburb = this.shippingAddress.address.suburb;
      let post_code = this.shippingAddress.address.postCode;
      let state = this.shippingAddress.address.state;
      let country = this.shippingAddress.address.country;
      let user_id = this.customer.user_id

      this.loader(true);
      const currentDate = new Date();
      this.order_date = currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + currentDate.getDate()).slice(-2);

      const dataObj = {
        street: street,
        suburb: suburb,
        post_code: post_code,
        country: country,
        state: state,
        date: currentDate.getFullYear() + '-' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + currentDate.getDate()).slice(-2), //new Date().toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}),

        // time: "19:00"
        time: ('0' + currentDate.getHours()).slice(-2) + ":" + ('0' + currentDate.getMinutes()).slice(-2), //new Date().toLocaleTimeString('en-GB', {hour12: false, hour: '2-digit', minute: '2-digit'}),

        user_id: user_id,
        coupon_code: this.applyCoupon ? this.coupon_code : '',
        coupon_apply_on: this.applyCoupon ? this.apply_on : '',
        credited_payment_discount_amount: this.applyCredit ? this.credit_code * 100 : '',
        applied_discount_amount: this.discount_type !== 'any' ? this.discount_type : ((this.discount_amount) * 100),
        applied_discount_type: this.discount_type !== 'any' ? 0 : 1,
        item_price: this.getTotalPrice(), // calculated total

        // if order comes through appointment
        appointment_id: this.appointment?.id ?? null,
        apply_delivery_charge: this.orderDeliveryChargeStatus ? 1 : 0
      };

      await this.preStoreOrderData(dataObj).then((response) => {

        if (response && response.status === 200) {
          this.loader(false);
          if (response.data.status === true) {

            this.preOrderResponse.status = response.data.status;
            this.preOrderResponse.message = response.data.message;

            this.preOrderResponse.remaining_credited_payment = response.data.remaining_credited_payment;

            this.preOrderResponse.order_delivery_charge.applied_status = response.data.order_delivery_charge.applied_status;
            this.preOrderResponse.order_delivery_charge.distance_value = response.data.order_delivery_charge.distance_value;
            this.preOrderResponse.order_delivery_charge.distance_value_in_km = response.data.order_delivery_charge.distance_value_in_km;
            this.preOrderResponse.order_delivery_charge.amount = response.data.order_delivery_charge.amount;

            this.preOrderResponse.subtotal_amount = response.data.subtotal_amount;

            this.preOrderResponse.applied_discount.applied_status = response.data.applied_discount.applied_status;
            this.preOrderResponse.applied_discount.amount = response.data.applied_discount.amount;

            this.preOrderResponse.coupon_discount.amount = response.data.coupon_discount.amount;
            this.preOrderResponse.coupon_discount.applied_status = response.data.coupon_discount.applied_status;
            this.preOrderResponse.coupon_discount.validation_status = response.data.coupon_discount.validation_status;
            this.preOrderResponse.coupon_discount.message = response.data.coupon_discount.message;
            this.preOrderResponse.coupon_discount.apply_on = response.data.coupon_discount.apply_on;

            this.preOrderResponse.credited_payment_discount.applied_status = response.data.credited_payment_discount.applied_status;
            this.preOrderResponse.credited_payment_discount.validation_status = response.data.credited_payment_discount.validation_status;
            this.preOrderResponse.credited_payment_discount.amount = response.data.credited_payment_discount.amount;
            this.preOrderResponse.credited_payment_discount.message = response.data.credited_payment_discount.message;

            this.preOrderResponse.total_discount_amount = response.data.total_discount_amount;

            this.preOrderResponse.gst_charge.amount = response.data.gst_charge.amount;
            this.preOrderResponse.gst_charge.applied_status = response.data.gst_charge.applied_status;

            this.preOrderResponse.grand_total = response.data.grand_total ?? 0;

            this.preOrderResponse.shipping_address_status = this.sameBillingShippingAddressStatus;
            this.preOrderResponse.shipping_address = this.shippingDetails;

            this.getAvailableValidCouponsHandler();
            // this.getPaidAmount(this.payment_type);

          } else {
            this.showToastMessage({
              message: response.data.message,
              type: "error"
            });
          }
        } else {
          this.showToastMessage({
            message: response.message,
            type: "error"
          });
          this.loader(false);
        }
      });

    },
    // Order process action
    async createOrderHandler() {
      // shipping data filled
      if (!this.isShippingInfoDataFiled) {
        this.showToastMessage({
          type: 'warning',
          message: 'please provide shipping information'
        });
        return;
      }
      // if add payment && payment type must be selected link quarter, half, full
      if (this.withPayment && this.payment_type === '') {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select payment amount'
        });
        return;
      }
      if (this.withPayment && this.payment_type === 'other' && (this.paid_amount <= 0 || this.paid_amount === '')) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please enter valid amount'
        });
        return;
      }

      if (this.withPayment && this.payment_type !== '' && this.payment_method_type === '') {
        this.showToastMessage({
          type: 'warning',
          message: 'Please select payment method'
        });
        return;
      }
      // if add payment & cash payment & not check cash received
      if (this.withPayment && this.payment_method_type === 'cashPayment' && !this.isCashReceived) {
        this.showToastMessage({
          type: 'warning',
          message: 'If you received the payment in cash then mark on cash received otherwise create appointment without payment'
        });
        return;
      }
      // if add payment & eft payment & reference text must be 2 character
      if (this.withPayment && this.payment_method_type === 'eftPayment' && this.eft_reference.length < 2) {
        this.showToastMessage({
          type: 'warning',
          message: 'Please enter reference at least two character'
        });
        return;
      }

      if (this.withPayment && this.payment_method_type === 'cardPayment') {
        console.log('cardLoop');
        const isTokenCreated = await this.tokenCreate();
        if (isTokenCreated === true) {
          const isAuthorized = await this.createAuthorizationByToken();
          if (isAuthorized) {
            const isOrderCreated = await this.createOrder();
            if (isOrderCreated) {
              const isPaymentCreated = await this.createPayment();
              if (isPaymentCreated) {
                const isCardPaymentDone = await this.createCardPayment();
                if (isCardPaymentDone) {
                  const isOrderCreatorDone = await this.createOrderCreator();
                  if (isOrderCreatorDone) {
                    this.createOrderDiscountStoreList();
                    this.orderEmailNotify();
                    this.orderPaymentEmailNotify();
                    this.showToastMessage({
                      message: 'Order created Successfully',
                      type: 'success'
                    });

                    document.querySelector('[data-target="#showOrderNo"]').click();
                    clearTimeout(this.orderSuccessDebounce);
                    this.orderSuccessDebounce = setTimeout(async () => {
                      await this.onConfirmActionModal({
                        status: true
                      });
                    }, 10000);
                  }
                }
              }
            }
          }
        }
      } else if (this.withPayment && this.payment_method_type === 'cashPayment') {
        console.log('cashLoop');
        const isOrderCreated = await this.createOrder();
        if (isOrderCreated) {
          const isPaymentCreated = await this.createPayment();
          if (isPaymentCreated) {
            const isCashPaymentDone = await this.createCashPayment();
            if (isCashPaymentDone) {
              const isOrderCreatorDone = await this.createOrderCreator();
              if (isOrderCreatorDone) {
                this.createOrderDiscountStoreList();
                this.orderEmailNotify();
                this.orderPaymentEmailNotify();
                this.showToastMessage({
                  message: 'Order created Successfully',
                  type: 'success'
                });

                document.querySelector('[data-target="#showOrderNo"]').click();
                clearTimeout(this.orderSuccessDebounce);
                this.orderSuccessDebounce = setTimeout(async () => {
                  await this.onConfirmActionModal({
                    status: true
                  });
                }, 10000);
              }
            }
          }
        }
      } else if (this.withPayment && this.payment_method_type === 'eftPayment') {
        const isOrderCreated = await this.createOrder();
        if (isOrderCreated) {
          const isPaymentCreated = await this.createPayment();
          if (isPaymentCreated) {
            const isCashPaymentDone = await this.createEftPayment();
            if (isCashPaymentDone) {
              const isOrderCreatorDone = await this.createOrderCreator();
              if (isOrderCreatorDone) {
                this.createOrderDiscountStoreList();
                this.orderEmailNotify();
                this.orderPaymentEmailNotify();
                this.showToastMessage({
                  message: 'Order created',
                  type: 'success'
                });

                document.querySelector('[data-target="#showOrderNo"]').click();
                clearTimeout(this.orderSuccessDebounce);
                this.orderSuccessDebounce = setTimeout(async () => {
                  await this.onConfirmActionModal({
                    status: true
                  });
                }, 10000);
              }
            }
          }
        }
      } else {
        // without payment
        console.log('without payment');
        const isOrderCreated = await this.createOrder();
        if (isOrderCreated) {
          const isOrderCreatorDone = await this.createOrderCreator();
          if (isOrderCreatorDone) {
            this.createOrderDiscountStoreList();
            this.orderEmailNotify();
            this.showToastMessage({
              message: 'Order created',
              type: 'success'
            });

            document.querySelector('[data-target="#showOrderNo"]').click();
            clearTimeout(this.orderSuccessDebounce);
            this.orderSuccessDebounce = setTimeout(async () => {
              await this.onConfirmActionModal({
                status: true
              });
            }, 10000);
          }
        }
      }

      if (this.order_note !== '') {
        await this.createOrderNotes();
      }
      // if order comes  form appointment list then add order id to appointment table
      if (this.appointment?.id) {
        console.log('order come from appointment where id:', this.appointment?.id);
        await this.updateSingleAppointment(this.appointment.id);
      }

    },

    async applyDiscountHandler() {
      await this.preStoreOrder();
    },

    //sequence of  card payment
    //* step 1 create token, get card type
    async tokenCreate() {

      const data = {
        card_name: this.cardName,
        card_number: this.cardNumber,
        card_expire_month: this.cardExpireMonth,
        card_expire_year: this.cardExpireYear,
        card_cvc: this.cardCvc,
      }
      this.loader(true);
      return await this.cardTokenCreate(data).then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response, "step 1: card token response result")
          this.loader(false);
          this.cardTokenBackendErrors = {};
          this.cardTokenResponse.id = response.data.id;
          this.cardTokenResponse.used = response.data.used;
          this.cardTokenResponse.card = response.data.card;
          if (response.data.used === false) {
            let cardType = this.cardTokenResponse.card.type;
            this.cardPaymentAmountObj = this.getSurcharge(this.surcharge, cardType, this.paidInfo.paidAmount)
            return true;
          }

        } else {
          this.cardTokenResponse.id = '';
          this.cardTokenResponse.used = '';
          this.cardTokenResponse.card = {};
          this.loader(false);
          this.cardTokenBackendErrors = response.errors;
        }
        if (response.message) {
          this.showToastMessage(response);
        }
        return false;

      });

    },
    //* step 3  create & get order id,order_no
    async createOrder() {
      // add cart data in pre order response object

      const data = {
        user_id: this.customer.user_id,
        order_date: this.order_date,
        delivery_date: this.delivery_date,
        status: 0, // 0 for pending
        shipping_address_id: this.shippingAddressId,
        billing_address_id: this.billingAddressId,
        details: JSON.stringify(this.preOrderResponse), // json pre store data
      }

      this.loader(true);
      const response = await this.postOrder(data);
      if (response.status === 200 || response.status === 201) {
        this.loader(false);
        console.log(response, 'step 3: order create response result')
        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;

    },
    //step 3 create note -> used user_id,user_type=0, type=0, step 2: order id,
    async createOrderNotes() {

      const data = {
        user_id: this.customer.user_id,
        order_id: this.order.id,
        user_type: 0, // 0 for customer
        type: 0, // setting table
        description: this.order_note
      }

      const response = await this.postOrderNote(data);

      if (response.status === 200 || response.status === 201) {
        console.log(response, "order note response result")
        this.showToastMessage({
          message: 'Order note created',
          type: 'success'
        });
        return true;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false;

    },
    // step 2 -> used step 1: token & step 2: order_no, get gateway id
    async createAuthorizationByToken() {
      let data = {
        currency: 'AUD',
        reference: 'order-admin-panel',
        amount: this.cardPaymentAmountObj.total_amount,
        description: `${this.customer.full_name} paid $ ${this.preOrderResponse.grand_total} from Admin Panel`,
        card_token: this.cardTokenResponse.id,
      }
      this.loader(true);
      const response = await this.authorizationCreateByToken(data);
      if (response.status === 200 || response.status === 201) {
        console.log(response, "step2 :after authorization token response result");
        this.loader(false);
        if (response.data.payment_status === 'APPROVED') {
          console.log(response, "authorized by token success");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          this.authorizationTokenResponse.payment_gateway = response.data.payment_gateway;
          return true;
        }
        if (response.data.payment_status === 'DECLINED') {
          console.log(response, "authorized by token declined");
          this.authorizationTokenResponse.id = response.data.id;
          this.authorizationTokenResponse.payment_status = response.data.payment_status;
          this.authorizationTokenResponse.decline_reason = response.data.decline_reason;
          this.showToastMessage({
            type: 'error',
            message: 'Card  Declined'
          })
          return false;
        }

      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },
    // step 5 ->used step 2: order_no , get payment id
    async createPayment() {
      const data = {
        reference: this.order.order_no,
        type: this.payment_method_type === 'cardPayment' ? 1 : (this.payment_method_type === 'cashPayment' ? 0 : 1), // 0= cash, 1=card, 2= eftpos
        panel: 1,
      }
      this.loader(true);
      const response = await this.postPayment(data);
      if (response.status === 200 || response.status === 201) {
        this.paymentId = this.payment.id
        console.log(response, "step 4:after payment response result");
        return true;
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },
    // step 6 -> used step 1:card type, step 3: gateway id, step 4: payment id
    async createCardPayment() {
      const data = {
        payment_id: this.paymentId,
        paid_by: this.customer.user_id,
        amount: this.paidInfo.paidAmount,
        card_type: this.cardTokenResponse.card.type,
        card_surcharge: this.cardPaymentAmountObj.surcharge_amount,
        payment_gateway: this.authorizationTokenResponse.payment_gateway,
        payment_gateway_id: this.authorizationTokenResponse.id,
        status: 0 // 0 for hold, 1 for paid

      }
      this.loader(true);
      const response = await this.postCardPayment(data);
      if (response.status === 200 || response.status === 201) {
        console.log(response, "step 5:  card payment response result");
        this.loader(false);
        return true;
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },
    // end card payment

    //sequence of cash payment
    // step 1:-> step 5-> next cash payment api call
    // cash payment api call
    async createCashPayment() {
      const data = {
        payment_id: this.paymentId,
        paid_by: this.customer.user_id,
        paid_to: this.authUser.id,
        handover_date: '',
        amount: this.paidInfo.paidAmount,

      }
      this.loader(true);
      const response = await this.postCashPayment(data);
      if (response.status === 200 || response.status === 201) {
        console.log(response, "step 5:  cash payment response result");
        this.loader(false);
        return true;
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },
    // end cash payment

    //sequence of eft payment
    // step 1:-> step 5-> next cash payment api call
    // eft payment api call
    async createEftPayment() {
      const data = {
        payment_id: this.paymentId,
        paid_by: this.customer.user_id,
        payment_reference: this.eft_reference,
        amount: this.paidInfo.paidAmount,

      }
      this.loader(true);
      const response = await this.postEftPayment(data);
      if (response.status === 200 || response.status === 201) {
        console.log(response, "step 5: eft payment response result");
        this.loader(false);
        return true;
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },

    async updateSingleAppointment(appointmentId) {
      let dataObj = {
        id: appointmentId,
        data: {
          order_id: this.order.id
        },
      }
      this.loader(true);
      const response = await this.updateAppointment(dataObj);
      if (response.status === 200) {
        this.loader(false);
        return true
      } else {
        this.loader(false);
      }
      if (response.message) {
        this.showToastMessage(response);
      }
      return false
    },

    async backToOrderCreate() {

      const orderData = {
        cartList: this.editOrderTemporalInformation?.data?.cartList ?? [],
        selectedCustomer: this.editOrderTemporalInformation.data.selectedCustomer,

      };
      this.$store.commit("appOrders/SET_EDIT_ORDER_TEMPORAL_INFORMATION", {
        data: orderData,
        orderCheckoutIsActive: false
      });
    },

    orderEmailNotify() {
      const data = {
        id: this.order.id,
        params: {
          notify_customer: this.notifyToCustomer ? 1 : 0,
          notify_internal_user: 1
        }

      };

      this.postOrderCreationNotifyEmail(data);
    },

    orderPaymentEmailNotify() {
      const data = {
        id: this.paymentId,
        params: {
          notify_customer: 1,
          notify_internal_user: 1
        }

      };
      this.postPaymentCreationNotifyEmail(data);
    },

    createOrderDiscountStoreList() {
      /*
        0:Credited Payment,
        1:Applied on Subtotal,
        2:Applied on Shipping Fee,
        3:Coupon on Subtotal,
        4:Coupon on Shipping Fee,
        5:Parking,
        6:Online Appointment
        */
      let discountsArray = [];

      if (this.preOrderResponse.coupon_discount.applied_status) {
        discountsArray.push({
          "amount": this.preOrderResponse.coupon_discount.amount,
          "type": this.preOrderResponse.coupon_discount.apply_on === 0 ? 3 : 4,
        });
      }
      if (this.preOrderResponse.applied_discount.applied_status) {
        discountsArray.push({
          "amount": this.preOrderResponse.applied_discount.amount,
          "type": 1,

        });
      }
      if (this.preOrderResponse.credited_payment_discount.applied_status) {
        discountsArray.push({
          "amount": this.preOrderResponse.credited_payment_discount.amount,
          "type": 0,

        });
      }
      if (discountsArray.length > 0) {
        const dataObj = {
          user_id: this.customer.user_id,
          reference: this.order.order_no,
          discounts: discountsArray
        }
        this.postDiscountStoreList(dataObj);
      }
    },

    async createOrderCreator() {
      const data = {
        user_id: this.authUser.id,
        order_id: this.order.id,
        panel: 1 // 1 for system web app
      };
      this.loader(true);
      const response = await this.postOrderCreator(data);
      console.log(response, "order creators");
      if (response.status === 200 || response.status === 201) {
        this.loader(false);
        return true;
      } else {
        this.loader(false);
        return false
      }

    },

  },
  created() {
    this.sameBillingShippingAddressStatus = this.order.shippingAddress?.id === this.order.billingAddress.id;
  },

  async mounted() {
    this.loader(true);

    await this.getSettings(this.getSettingsParams);

    // customer information
    this.customer.address = this.editOrderTemporalInformation.data.selectedCustomer.address;
    this.customer.address_id = this.editOrderTemporalInformation.data.selectedCustomer.address.id;
    this.customer.user_id = this.editOrderTemporalInformation.data.selectedCustomer.user_id;
    this.customer.type = this.editOrderTemporalInformation.data.selectedCustomer.type;

    // billing information
    this.billingAddress.name = this.editOrderTemporalInformation.data.selectedCustomer.full_name;
    this.billingAddress.email = this.editOrderTemporalInformation.data.selectedCustomer.email;
    this.billingAddress.phone = this.editOrderTemporalInformation.data.selectedCustomer.phone_number;

    this.billingAddress.address.userId = this.editOrderTemporalInformation.data.selectedCustomer.user_id;
    this.billingAddress.address.id = this.editOrderTemporalInformation.data.selectedCustomer.address.id;
    this.billingAddress.address.street = this.editOrderTemporalInformation.data.selectedCustomer.address.street;
    this.billingAddress.address.state = this.editOrderTemporalInformation.data.selectedCustomer.address.state;
    this.billingAddress.address.suburb = this.editOrderTemporalInformation.data.selectedCustomer.address.suburb;
    this.billingAddress.address.postCode = this.editOrderTemporalInformation.data.selectedCustomer.address.post_code;
    this.billingAddress.address.country = this.editOrderTemporalInformation.data.selectedCustomer.address.country;
    this.billingAddress.address.business.id = this.editOrderTemporalInformation.data.selectedCustomer.address.business?.id ?? null;
    this.billingAddress.address.business.name = this.editOrderTemporalInformation.data.selectedCustomer.address.business?.name ?? '';

    this.billingAddress.address.fullAddress = this.customer.type === 'Business' ? `${this.billingAddress.address.business.name} (${this.billingAddress.address.street}, ${this.billingAddress.address.suburb} ${this.billingAddress.address.state} ${this.billingAddress.address.postCode}, ${this.billingAddress.address.country})` : `${this.billingAddress.address.street}, ${this.billingAddress.address.suburb} ${this.billingAddress.address.state} ${this.billingAddress.address.postCode}, ${this.billingAddress.address.country}`

    //   shipping address
    this.shippingAddress.name = this.editOrderTemporalInformation.data.selectedCustomer.full_name;
    this.shippingAddress.email = this.editOrderTemporalInformation.data.selectedCustomer.email;
    this.shippingAddress.phone = this.editOrderTemporalInformation.data.selectedCustomer.phone_number;
    this.shippingAddress.address.userId = this.editOrderTemporalInformation.data.selectedCustomer.user_id;

    this.shippingAddress.address.id = this.order?.shippingAddress?.id ?? '';
    this.shippingAddress.address.street = this.order?.shippingAddress?.street ?? '';
    this.shippingAddress.address.state = this.order?.shippingAddress?.state ?? '';
    this.shippingAddress.address.suburb = this.order?.shippingAddress?.suburb ?? '';
    this.shippingAddress.address.postCode = this.order?.shippingAddress?.post_code ?? '';
    this.shippingAddress.address.country = this.order?.shippingAddress?.country ?? '';
    this.shippingAddress.address.business.id = this.order?.shippingAddress?.business?.id ?? null;
    this.shippingAddress.address.business.name = this.order?.shippingAddress?.business?.name ?? '';

    this.shippingAddress.address.fullAddress = this.customer.type === 'Business' ?
        `${this.shippingAddress.address.business.name} (${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country})`
        : `${this.shippingAddress.address.street}, ${this.shippingAddress.address.suburb} ${this.shippingAddress.address.state} ${this.shippingAddress.address.postCode}, ${this.shippingAddress.address.country}`;

    // set delivery date.
    this.delivery_date = this.order?.delivery_date ?? '';

    // order note
    this.order_note = this.order?.orderNotes?.[0]?.description ?? '';

    // card data set
    this.preOrderResponse.cart_data = this.editOrderTemporalInformation?.data?.cartList ?? [];

    // checkout data set
    this.preOrderResponse.checkout_data = this.editOrderTemporalInformation?.data?.checkoutData;
    const checkout_data = this.preOrderResponse.checkout_data;
    this.applyCoupon = checkout_data?.coupon_apply_on !== '';
    if (this.applyCoupon) {
      this.apply_on = checkout_data?.coupon_apply_on;
      this.coupon_code = checkout_data?.coupon_code;
    }
    this.discount_type = (checkout_data?.applied_discount_type !== 0) ? 'any'
        : checkout_data?.applied_discount_amount;
    this.discount_amount = this.discount_type === 'any' ? checkout_data?.applied_discount_amount / 100 : '';
    this.orderDeliveryChargeStatus = checkout_data?.apply_delivery_charge === 1;

    // again call pre-store order api
    await this.preStoreOrder();
    await this.loader(false);
  },

}
</script>

<style scoped>
.custom-control-label {
  font-size: 13px;
}

.payment-input-field {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  width: 100%;
  padding: 4px 15px
}

.payment-input-field:focus {
  outline: 0 !important;
}

.payment-input-field:focus {
  border: 1px solid #5a8dee !important;
}

span {
  font-size: 14px;
}
</style>
